import { ReactElement } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../common/pov-common-ui';
import classNames from 'classnames';
import { infoActions } from '../../../../../redux/admin/info/actions';

interface IProps {
  applyChanges: typeof infoActions.applyRebalanceChanges;
  initialTotalTargetSessions: number;
  filterBy: IRebalanceFilterValues;
  targetInputIsDirty: boolean;
  totalTargetSessions: number;
  expoUid: string;
}

export default function RebalanceControls({
  applyChanges,
  filterBy,
  initialTotalTargetSessions,
  targetInputIsDirty,
  totalTargetSessions,
  expoUid,
}: IProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="rebalance-controls-wrapper">
      <span className="rebalance-data-center">Rebalance Session Count: </span>
      <span className="rebalance-data-center-details">
        {filterBy.contentPool.name}{' '}
        <span
          className={classNames('rebalance-data-center-figure', {
            'rebalance-data-center-figure--error':
              totalTargetSessions !== initialTotalTargetSessions,
          })}
        >
          {totalTargetSessions}/{initialTotalTargetSessions}
        </span>
      </span>
      <Button
        onClick={() => applyChanges(expoUid)}
        color="primary"
        id="validate"
        disabled={!targetInputIsDirty}
      >
        {t('manage.rebalanceControls.applyChanges')}
      </Button>
    </div>
  );
}
