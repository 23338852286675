const en = {
  admin: {
    info: {
      content: 'Content',
      endExpo: 'End eXpo',
      rebalance: 'Rebalance',
      users: 'Users',
    },
  },
  cardButtons: {
    accessCode: 'Access Code',
    edit: 'Edit',
    manage: 'Manage',
    unpublish: 'Unpublish',
  },
  components: {
    modal: {
      buttons: {
        cancel: 'Cancel',
        end: 'End',
      },
      endExpo: {
        title: 'End eXpo',
        content: {
          note: 'By ending the eXpo before its scheduled end date, all sessions and user engagements will be terminated. Once ended, this action cannot be reversed.',
          question: 'Are you sure you want to End this eXpo?',
        },
        messages: {
          success: {
            title: 'Success',
            content: "eXpo '{{expoName}}' has been ended",
          },
          error: {
            title: 'Error',
          },
        },
      },
    },
    timezoneInput: {
      timezone: 'Timezone',
    },
  },
  dCloudLogo: {
    topLine: 'Labs brought to you by',
    bottomLine: 'Cisco dCloud',
  },
  manage: {
    rebalanceControls: {
      applyChanges: 'Apply Changes',
    },
  },
  publishExpo: {
    cancel: 'Cancel',
    publish: 'Publish',
    title: 'Publish',
    content: {
      note: 'Please note that documentation for demos associated to an eXpo are directly accessible via the public Internet whilst the eXpo is running. If your eXpo contains restricted content such as non-FCS code, please contact the dCloud Support Team before publishing the eXpo.',
      question: 'Are you sure you want to publish this eXpo?',
    },
  },
};

export default en;
